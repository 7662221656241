/*!
 * Mythril v2.0.0
 * Copyright 2017 Guillaume Gautier
 * Based on Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import 'functions';
$primary: #4468ae;
@import 'variables';
@import 'mixins';

// Reset and dependencies
@import 'root';
@import 'print';

@import 'reboot';
@import 'type';
@import 'fonts';
@import 'images';
@import 'code';
@import 'grid';
@import 'tables';
@import 'tables-jobaround';
@import 'forms';
@import 'forms-jobaround';
@import 'buttons';

// Components
//@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'input-group';
@import 'custom-forms';
//@import 'nav';
//@import 'navbar';
@import 'card';
@import 'card-jobaround';
@import 'breadcrumb';
@import 'pagination';
@import 'badge';
//@import 'jumbotron';
@import 'alert';
//@import 'progress';
//@import 'media';
@import 'list-group';
//@import 'close';
@import 'icons';
@import 'icons-glyphicons';
@import 'bootstrap-stars';
@import 'layout';
@import 'navs';
@import 'header';
@import 'hero';

@import 'popup-layout';
@import 'popup';
@import 'chosen';
@import 'modules';
@import 'footer';

@import 'register-login';
@import 'forgotten_password';
@import 'messagerie';
@import '404';
@import 'beta';

// Components w/ Javascript
//@import 'modal';
@import 'tooltip';
//@import 'popover';
//@import 'carousel';
@import 'swiper';
@import 'unslider';
@import 'daterangepicker';
@import 'bootstrap-slider';
@import 'bootstrap-slider-jobaround';
@import 'fullcalendar';
@import 'qtip';
@import 'flexslider';
@import 'jquery-ui';
@import 'autocomplete-mainsearch';

// Utility classes
@import 'utilities';

// Visual guides
//@import 'visual_guides/breakpoints';
//@import 'visual_guides/grid';

body{
	letter-spacing: .05em;
}

// fonts
.script{
	font-family: $font-family-script;
}


.pointer{
	cursor: pointer;
}

.text-weight-light{
	font-weight: lighter;
}


.deactivated{
	*{
		user-select: none;
		cursor: default;
	}
	&::after{
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(100,100,100,.7);
		z-index: 99; // header z-index 100;
	}
}


@include media-breakpoint-up(xs) {
	.w-xs-100{width: 100%;}
	.w-xs-auto{width: auto;}
}

@include media-breakpoint-up(sm) {
	.w-sm-100{width: 100%;}
	.w-sm-auto{width: auto;}
}

@include media-breakpoint-up(md) {
	.w-md-100{width: 100%;}
	.w-md-auto{width: auto;}
}

@include media-breakpoint-up(lg) {
	.w-lg-100{width: 100%;}
	.w-lg-auto{width: auto;}
}

@include media-breakpoint-up(xl) {
	.w-xl-100{width: 100%;}
	.w-xl-auto{width: auto;}
}

// bug fix safari

ul li a{display: block;}

